import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Double & Triple Unders`}</p>
    <p>{`then,`}</p>
    <p>{`2 Rounds for time of:`}</p>
    <p>{`500M Row`}</p>
    <p>{`15 Burpees`}</p>
    <p>{`1.0 Mile Assault Bike`}</p>
    <p>{`15 Burpees`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`15 Burpees`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`15 Burpees`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      